import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionEventTypeMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.events ? publicData.events : [];
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));
  return (
    <div className={css.sectionFeatures}>
      <PropertyGroup
        id="ListingPage.eventTypeTitle"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
        displayFieldName="events"
      />
    </div>
  );
};

export default SectionEventTypeMaybe;
