import React, { Component, useRef } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours, resetToStartOfDay } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  updateLikes,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionAmenitiesMaybe from './SectionAmenitiesMaybe';
import SectionEventTypeMaybe from './SectionEventTypeMaybe';
import SectionAvailabilityMaybe from './SectionAvailabilityMaybe';
import SectionMinMaxBookingHrsMaybe from './SectionMinMaxBookingHrsMaybe';
import SectionMediaLinkAvlMaybe from './SectionMediaLinkAvlMaybe';
import SectionSpaceRulesMaybe from './SectionSpaceRulesMaybe';
import SectionReviews from './SectionReviews';
import SectionShareYourSpace from './SectionShareListing';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';
import SectionSummaryMaybe from './SectionSummaryMaybe';
import SectionHourlyAndDailyPriceMaybe from './SectionHourlyAndDailyPriceMaybe';
import SectionHostMaybe from './SectionHostMaybe';
import SectionPoliciesMaybe from './SectionPoliciesMayBe';
import SectionTagsMaybe from './SectionTagsMayBe';
import SectionSpaceOwnerConnections from './SectionSpaceOwnerConnections';
import SectionLikes from './SectionLikes';
import SectionFloorPlanMaybe from './SectionFloorPlanMaybe';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const dailyPriceData = (dailyPriceAsMoney, intl) => {
  if (dailyPriceAsMoney && dailyPriceAsMoney.currency == config.currency) {
    const formattedDailyPrice = formatMoney(intl, dailyPriceAsMoney);
    return { formattedDailyPrice, dailyPrice: formattedDailyPrice };
  } else if (dailyPriceAsMoney) {
    return {
      formattedDailyPrice: `(${dailyPriceAsMoney.currency})`,
      dailyPrice: `Unsupported currency (${dailyPriceAsMoney.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      popupName: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handleReviewScroll = this.handleReviewScroll.bind(this);
    this.sectionReviewRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      const { getListing, params, intl } = this.props;
      const listingId = new UUID(params.id);
      const listing = getListing(listingId);
      if(!listing){
        return false;
      }
      const { attributes, id, author } = listing;
      const { attributes: userAttributes } = author;
      const { publicData } = attributes;
      const { formattedPrice, priceTitle } = priceData(attributes.price, intl);
      if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_item',
          // selected_item_list_id: id.uuid,
          // selected_item_list_name: attributes.title,
          ecommerce: {
            items: [
              {
                item_id: id.uuid,
                item_name: attributes.title,
                affiliation: userAttributes.profile.displayName,
                item_brand: userAttributes.profile.displayName,
                item_category: publicData.category,
                price: priceTitle.replaceAll(',', ''),
              },
            ],
          },
        });
        window.dataLayer.push({ ecommerce: null });

        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            currency: 'USD',
            items: [
              {
                item_id: id.uuid,
                item_name: attributes.title,
                affiliation: userAttributes.profile.displayName,
                item_brand: userAttributes.profile.displayName,
                item_category: publicData.category,
                price: priceTitle.replaceAll(',', ''),
              },
            ],
          },
        });
      }
    }, 2000);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      intl,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);
    const bookingDisplayStart = timestampToDate(values.currentBookingStartTime);
    const bookingDisplayEnd = timestampToDate(values.currentBookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
        bookingDisplayStart,
        bookingDisplayEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
      const { attributes, author } = listing;
      const { attributes: userAttributes } = author;
      const { publicData } = attributes;
      const { formattedPrice, priceTitle } = priceData(attributes.price, intl);
      const dailyPriceAsMoney = publicData.dailyPrice
        ? new Money(publicData.dailyPrice.amount, publicData.dailyPrice.currency)
        : null;
      const { lineItems } = this.props;

      let totalPrice = lineItems.find(val => {
        return val.code === 'line-item/provider-commission';
      });

      const { unitPrice } = totalPrice;
      const { formattedDailyPrice, dailyPrice } = dailyPriceData(dailyPriceAsMoney, intl);
      const priceValue = restOfValues.bookingType === 'hourlyBooking' ? priceTitle : dailyPrice;
      let { formattedDailyPrice: formattedTotalPrice, dailyPrice: totalTitle } = dailyPriceData(
        unitPrice,
        intl
      );
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'add_to_cart',
        currency: 'USD',
        ecommerce: {
          items: [
            {
              item_id: listingId.uuid,
              item_name: attributes.title,
              affiliation: userAttributes.profile.displayName,
              item_brand: userAttributes.profile.displayName,
              item_category: publicData.category,
              price: priceValue.replaceAll(',', ''),
            },
          ],
        },
      });
    }
  }

  onContactUser(popupname) {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true, popupName: popupname });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  handleReviewScroll() {
    this.sectionReviewRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onUpdateLikes,
      updateLikesInProgress,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const metaData =
      currentListing && currentListing.attributes && currentListing.attributes.metadata;
    const metaTitle = metaData && metaData.metaTitle;
    const metaDescription = metaData && metaData.metaDescription;

    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const { uuid: hostUerId } = ensuredAuthor.id;
    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const canonicalRootURL = config.canonicalRootURL;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const hostListedProfileLink = (
      <NamedLink className={css.authorNameLink} name="ProfilePage" params={{ id: hostUerId }}>
        {authorDisplayName}
      </NamedLink>
    );

    const eventTypeOptions = findOptionsForSelectFilter('events', filterConfig);
    const amenitiesOptions = findOptionsForSelectFilter('amenities', filterConfig);
    const additionalServiceOptions = findOptionsForSelectFilter('additionalservice', filterConfig);
    const certificateOptions = findOptionsForSelectFilter('certificate', filterConfig);
    const spaceRulesOptions = findOptionsForSelectFilter('locationrules', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
    const categoryName = publicData.category;
    const subCategoryList = categoryOptions.map(val => {
      if (Object.keys(val).includes('subcategory')) {
        return val.subcategory;
      }
    });
    const categoryCombineOptions = [...categoryOptions, ...subCategoryList].flat().filter(element => {
      return element !== undefined;
    }).find((res)=>{
      return res.key === categoryName;
    });
    const currentUrl = `${canonicalRootURL}${location.pathname}`;
    const schemaList = [
      {
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: metaDescription ? metaDescription : description,
        name: metaTitle ? metaTitle : schemaTitle,
        image: schemaImages,
      },
      {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: `${title}`,
        image: schemaImages,
        description: `${description}`,
        brand: {
          '@type': 'Brand',
          name: `${categoryCombineOptions.label}`,
        },
        "offers": { 
          "@type": "Offer",
          "url": `${currentUrl}`,
          "priceCurrency": "USD",
          "price": `${priceTitle.slice(1)}`
        }
      },
    ];
    return (
      <Page
        title={metaTitle ? metaTitle : schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        description={metaDescription ? metaDescription : description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={schemaList}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.listingBanner}>
                <SectionImages
                  title={title}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingType,
                    tab: listingTab,
                  }}
                  imageCarouselOpen={this.state.imageCarouselOpen}
                  onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                />

                <SectionShareYourSpace
                  publicData={publicData}
                  onUpdateLikes={onUpdateLikes}
                  updateLikesInProgress={updateLikesInProgress}
                  listingId={listingId.uuid}
                  currentUser={currentUser}
                  listing={currentListing}
                  intl={intl}
                />
              </div>
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />

                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    listingCertificate={publicData ? publicData.certificate : null}
                    certificateOptions={certificateOptions}
                    hostLink={hostLink}
                    hostListedProfileLink={hostListedProfileLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    authorDisplayName={authorDisplayName}
                    publicData={publicData}
                    reviews={reviews}
                    handleReviewScroll={this.handleReviewScroll}
                    // onUpdateLikes={onUpdateLikes}
                    // updateLikesInProgress={updateLikesInProgress}
                    // listingId={listingId.uuid}
                    // currentUser={currentUser}
                  />
                  <SectionDescriptionMaybe description={description} />
                  {/* <SectionSummaryMaybe publicData={publicData} /> */}
                  <SectionFeaturesMaybe publicData={publicData} />
                  <SectionMinMaxBookingHrsMaybe publicData={publicData} />
                  {/* <SectionHourlyAndDailyPriceMaybe
                    publicData={publicData}
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                  /> */}
                  <SectionEventTypeMaybe options={eventTypeOptions} publicData={publicData} />
                  <SectionAmenitiesMaybe options={amenitiesOptions} publicData={publicData} />
                  <SectionSpaceOwnerConnections
                    user={currentAuthor}
                    params={params}
                    publicData={publicData}
                    authorDisplayName={authorDisplayName}
                    options={additionalServiceOptions}
                  />
                  <SectionFloorPlanMaybe
                    publicData={publicData}
                    title={title}
                    listing={currentListing}
                  />
                  {/* <SectionAvailabilityMaybe publicData={publicData} listing={availabilityPlan} /> */}
                  <SectionMediaLinkAvlMaybe publicData={publicData} />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <SectionSpaceRulesMaybe options={spaceRulesOptions} publicData={publicData} />
                  <SectionReviews
                    reviews={reviews}
                    fetchReviewsError={fetchReviewsError}
                    sectionReviewRef={this.sectionReviewRef}
                  />
                  <SectionPoliciesMaybe publicData={publicData} />
                  <SectionTagsMaybe publicData={publicData} />
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
                popupName={this.state.popupName}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  updateLikesInProgress: false,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  updateLikesInProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    updateLikesInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    updateLikesInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateLikes: (listingId, currentListing, priceTitle) =>
    dispatch(updateLikes(listingId, currentListing, priceTitle)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
