import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionSpaceRulesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions =
    publicData && publicData.alllocationrules ? publicData.alllocationrules : [];
  const otherRUles = publicData.otherrules ? publicData.otherrules : undefined;
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));
  const mergeVenuRules = [...selectedConfigOptions, otherRUles].filter(element => {
    return element !== undefined;
  });

  return (
    <>
      {mergeVenuRules.length > 0 ? (
        <>
          <div className={`${css.venuRulesWrap}`}>
            <h2 className={css.featuresTitle}>
              <FormattedMessage id="ListingPage.spaceRulesTitle" />
            </h2>
            <PropertyGroup
              id="ListingPage.spaceRulesTitle"
              options={mergeVenuRules}
              selectedOptions={selectedOptions}
              twoColumns={selectedConfigOptions.length > 3}
              venueRulesFlag={true}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default SectionSpaceRulesMaybe;
