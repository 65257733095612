import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage } from '../../components';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, PrevButton, NextButton } from './EmblaCarouselArrowDotBtn';

import css from './ListingPage.module.css';

const SectionFloorPlanMaybe = props => {
  const { publicData, title, listing, variants } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({});

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const floorPlan = publicData.floorImagesData;
  const hasImages = listing.images && listing.images.length > 0;
  const allImages = hasImages ? listing.images : null;
  let result = floorPlan ? allImages.find(obj => obj.id.uuid === floorPlan[0]) : '';
  const filterFloorplanImages = allImages.filter((val, index) => {
    return floorPlan && floorPlan.includes(val.id.uuid);
  });

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback(index => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  const floorPlanListShow = filterFloorplanImages.map((val, i) => {
    return (
      <div id={i} key={i} className={css.floorplan__embla__slide}>
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={val}
          variants={['landscape-crop', 'landscape-crop2x', 'landscape-crop4x', 'landscape-crop6x']}
        />
      </div>
    );
  });

  return (
    <div className={`${css.venuRulesWrap}`}>
      {filterFloorplanImages.length > 0 ? (
        <>
          <div>
            <h3 className={css.featuresAmenitiesTitle}>
              <FormattedMessage id="ListingPage.floorPlanImage" />
            </h3>
          </div>

          {filterFloorplanImages.length > 1 ? (
            <>
              <div className={css.floorPlanImage}>
                <div className={css.floorplan__embla}>
                  <div className={css.floorplan__embla__viewport} ref={emblaRef}>
                    <div className={css.floorplan__embla__container}>{floorPlanListShow}</div>
                  </div>

                  <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                  <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                </div>

                <div className={css.floorplan__embla__dots}>
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => scrollTo(index)}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>{floorPlanListShow}</>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SectionFloorPlanMaybe;
