import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  PinterestIcon,
  PinterestShareButton,
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
} from 'react-share';

import css from './ListingPage.module.css';
import SectionLikes from './SectionLikes';
import { toArray } from 'lodash';

const SectionShareYourSpace = props => {
  const { publicData, listing, ...rest } = props;
  const pageTitleEmail = listing && listing.attributes.title;
  const replaceWith = '-';
  const pageTitle = listing && listing.attributes.title.replaceAll(' ', replaceWith);
  const pageHeading = listing.attributes.title + ' ' + '|' + ' ' + 'Rent it on Swivo';

  const PinterestMedia =
    listing &&
    listing.images[0] &&
    listing.images[0].attributes &&
    listing.images[0].attributes.variants['scaled-medium'] &&
    listing.images[0].attributes.variants['scaled-medium'].url;

  const baseUrl = process.env.REACT_APP_CANONICAL_ROOT_URL;

  return (
    <div className={css.shareProduct}>
      <FacebookShareButton
        url={`${baseUrl}/l/${pageTitle}/${props.listingId}`}
        // quote={'Hello World'}
        // hashtag="#muo"
        title="Facebook"
      >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={`${baseUrl}/l/${pageTitle}/${props.listingId}`}
        appId="528273542809708"
        title="Messenger"
      >
        <FacebookMessengerIcon size={40} round />
      </FacebookMessengerShareButton>

      <span title="Twitter">
        <TwitterShareButton
          title={pageHeading}
          url={`${baseUrl}/l/${pageTitle}/${props.listingId}`}
        >
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
      </span>
      <PinterestShareButton
        url={`${baseUrl}/l/${pageTitle}/${props.listingId}`}
        media={PinterestMedia}
        title="Pinterest"
      >
        <PinterestIcon size={40} round={true} />
      </PinterestShareButton>
      <EmailShareButton
        subject="Check out this Swivo location"
        title="Email"
        body={`Check out this Swivo space: ${pageTitleEmail}
Link: `}
        url={`${baseUrl}/l/${pageTitle}/${props.listingId}`}
      >
        <EmailIcon size={40} round={true} />
      </EmailShareButton>

      {rest.currentUser ? (
        <SectionLikes
          {...rest}
          currentListing={listing}
          currentUser={rest.currentUser}
          intl={rest.intl}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SectionShareYourSpace;
