import React, { Fragment } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const bannerImages =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.bannerImagesData &&
    listing.attributes.publicData.bannerImagesData;

  const fisrtImageFlag = listing.images.filter((val, i) => {
    if (bannerImages && bannerImages.length > 0) {
      return val.id.uuid === bannerImages[0];
    }
  });

  /** 
  const firstImage = hasImages
    ? fisrtImageFlag.length > 0
      ? fisrtImageFlag[0]
      : listing.images[0]
    : null;
  */
  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  function getGridImageClassName(images = []) {
    const imagesArrLength = images.length;
    if (imagesArrLength >= 4) {
      return 'grid_for_fourorMore';
    } else {
      return `grid_for_${imagesArrLength}`;
    }
  }

  let listingFirstFourImages = [];
  if (hasImages && fisrtImageFlag.length > 0) {
    const otherThreeImages = listing.images
      .filter(each => each.id.uuid !== fisrtImageFlag[0].id.uuid)
      .slice(0, 3);
    listingFirstFourImages = [fisrtImageFlag[0], ...otherThreeImages];
  } else {
    listingFirstFourImages = listing.images.slice(0, 4);
  }

  const imageWrapClass = getGridImageClassName(listingFirstFourImages);

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div
          className={`${css.aspectWrapper} ${css.aspectWrapperOverride}`}
          onClick={handleViewPhotosClick}
        >
          {actionBar}
          <div className={`${css.imagesWrap} ${css[imageWrapClass]}`}>
            {listingFirstFourImages?.map((eachImg, index) => (
              <div
                key={`${eachImg}_${index + 1}`}
                className={`${css.eachImage} ${css[`eachImage_${index + 1}`]}`}
              >
                <ResponsiveImage
                  className={css.listingPageBannerSingleImage}
                  rootClassName={css.rootForImage}
                  alt={title}
                  image={eachImg}
                  variants={['scaled-large', 'scaled-medium']}
                />
              </div>
            ))}
          </div>
          {/* <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
            ]}
          /> */}
          {viewPhotosButton}
        </div>
      </div>
      {/* This is carousel */}
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          bannerImages={bannerImages}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;
