import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, ReviewRating } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import SectionLikes from './SectionLikes';
import css from './ListingPage.module.css';

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    richTitle,
    listingCertificate,
    certificateOptions,
    showContactUser,
    onContactUser,
    authorDisplayName,
    publicData,
    reviews,
    hostListedProfileLink,
    handleReviewScroll,
    // ...rest
  } = props;

  const category = publicData.category;
  const avgRating = publicData?.averagerating ? publicData.averagerating : null;
  const totalReview = reviews ? reviews.length : null;

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const subCategoryList = categoryOptions.map(val => {
    if (Object.keys(val).includes('subcategory')) {
      return val.subcategory;
    }
  });
  const combineOptions = [...categoryOptions, ...subCategoryList].flat().filter(element => {
    return element !== undefined;
  });

  const categoryLabel = combineOptions
    .filter((val, index) => {
      return val.key === category && val.label;
    })
    .map(values => {
      return values.label;
    });

  const certificate = getCertificateInfo(certificateOptions, listingCertificate);
  const showCertificate = certificate && !certificate.hideFromListingInfo;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {showCertificate ? <span>{certificate.label}</span> : null}

          <span className={css.contactWrapper}>
            {showCertificate ? <span className={css.separator}>•</span> : null}
            <span className={css.listingInfoPDP}>
              <span>{categoryLabel}</span>&nbsp;
              <span className={css.dotSpan}>&#9679;</span>&nbsp;Hosted by {hostListedProfileLink}
              {showContactUser ? (
                <>
                  <span className={css.dotSpan}>&#9679;</span>&nbsp;
                </>
              ) : (
                ''
              )}
            </span>

            {showContactUser ? (
              <>
                <InlineTextButton
                  rootClassName={css.contactLink}
                  onClick={()=>{onContactUser("contact")}}
                  enforcePagePreloadFor="SignupPage"
                >
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
                &nbsp;
                <span className={css.dotSpan}>&#9679;</span>&nbsp;
              </>
            ) : (
              ''
            )}

            {showContactUser ? (
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={()=>{onContactUser("reqQuote")}}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.RequestQuote" />
              </InlineTextButton>
            ) : (
              ''
            )}

            {/* &nbsp;
              <span className={css.dotSpan}>&#9679;</span> */}
          </span>

          {avgRating ? (
            <div className={css.ratingWrap}>
              <span className={css.dotSpan}>&#9679;</span>&nbsp;
              <ReviewRating
                rating={Number(avgRating)}
                className={css.ratingStarWrap}
                reviewStarClassName={css.reviewRatingStar}
              />
              {totalReview > 0 ? (
                <p className={css.totalReviewNo} onClick={handleReviewScroll}>
                  {totalReview} Reviews
                </p>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {/* <SectionLikes {...rest} /> */}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
