import React from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { findOptionsForSelectFilter } from '../../util/search';
// import config from '../../config';
import css from './ListingPage.module.css';

export const SectionMinMaxBookingHrsMaybe = props => {
  const { publicData, intl } = props;
  if (!publicData) {
    return null;
  }

  // const propertyType = publicData.propertyType;
  // const propertyTypeOptions = findOptionsForSelectFilter('propertyType', config.custom.filters);

  // const propertyTypeLabel = propertyTypeOptions
  //   .filter((val, index) => {
  //     return val.key === propertyType && val.label;
  //   })
  //   .map(values => {
  //     return values.label;
  //   });

  return (
    // <div className={`${css.sectionFeatures}, ${css.sectionOperationalHrsAlign}`}>
    //   <h3 className={`${css.operationlHrs}, ${css.spaceHighlights}`}>
    //     <FormattedMessage id="ListingPage.minBookingHrsTitle" />
    //     &nbsp;
    //     <span className={css.categoryName}>{publicData.minhours} hours</span>
    //   </h3>
    //   <h3 className={`${css.operationlHrs}, ${css.spaceHighlights}`}>
    //     <FormattedMessage id="ListingPage.maxBookingHrsTitle" />
    //     &nbsp;
    //     <span className={css.categoryName}>{publicData.maxhours} hours</span>
    //   </h3>
    // </div>
    <div className={`${css.sectionFeatures} ${css.sectionFeaturesAlign}`}>
      {/* <h3 className={`${css.featuresTitle} ${css.spaceHighlights} ${css.categoryLabels}`}>
        <FormattedMessage id="ListingPage.venueTypeTitle" />
        <span className={css.categoryName}>{propertyTypeLabel[0]}</span>
      </h3> */}
      <h3 className={`${css.featuresTitle} ${css.spaceHighlights}`}>
        <FormattedMessage id="ListingPage.minBookingHrsTitle" />{' '}
        <span className={css.sqFtNum}>{publicData.minhours} hours</span>
      </h3>
      <h3 className={`${css.featuresTitle} ${css.spaceHighlights}`}>
        <FormattedMessage id="ListingPage.maxBookingHrsTitle" />
        <span className={css.sqFtNum}>{publicData.maxhours} hours</span>
      </h3>
    </div>
  );
};

SectionMinMaxBookingHrsMaybe.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

SectionMinMaxBookingHrsMaybe.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SectionMinMaxBookingHrsMaybe);
