import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, sectionReviewRef } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );


  return (
    <>
      {reviews.length > 0 ? (
        <>
          <div className={css.sectionReviews} ref={sectionReviewRef}>
            <h2 className={css.reviewsHeading}>
              <FormattedMessage id="ListingPage.reviewsHeading" />
            </h2>
            {fetchReviewsError ? reviewsError : null}
            <div className={css.reviewWrap}>
              <Reviews reviews={reviews} plpPage={true} />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default SectionReviews;
