import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { AvatarLarge, AvatarMedium } from '../../components';
import css from './ListingPage.module.css';
import { PropertyGroup } from '../../components';

const SectionSpaceOwnerConnections = props => {
  const { options, publicData, user, authorDisplayName } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions =
    publicData && publicData.additionalservice ? publicData.additionalservice : [];
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <div className={css.sectionSpaceOwnerConnection}>
        <AvatarLarge
          user={user}
          className={`${css.avatarDesktop} ${css.spaceOwnerPhoto}`}
          initialsClassName={css.initialsDesktopForConnections}
          disableProfileLink
        />

        <AvatarMedium
          user={user}
          className={`${css.avatarMobile} ${css.avatarMobile1}`}
          disableProfileLink
        />

        <div className={css.spaceOwnerTitle}>
          <h2 className={`${css.featuresTitle} ${css.spacerNameAlign}`}>
            {authorDisplayName}
            <FormattedMessage id="ListingPage.spacerConnections" />
          </h2>
        </div>
      </div>
      <div className={css.spacerConnections}>
        <PropertyGroup
          id="ListingPage.amenities"
          options={selectedConfigOptions}
          selectedOptions={selectedOptions}
          twoColumns={selectedConfigOptions.length > 2}
        />
      </div>
    </div>
  );
};

export default SectionSpaceOwnerConnections;
