import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './ListingPage.module.css';

const SectionAmenitiesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }
  const selectedOptions = publicData && publicData.amenities ? publicData.amenities : [];
  const generalOptions = findOptionsForSelectFilter('generalamenities', config.custom.filters);
  const meetingOptions = findOptionsForSelectFilter('meetingamenities', config.custom.filters);
  const coporateOptions = findOptionsForSelectFilter('corporateamenities', config.custom.filters);
  const partiesOptions = findOptionsForSelectFilter('partiesamenities', config.custom.filters);
  const cookingOptions = findOptionsForSelectFilter('cookingamenities', config.custom.filters);
  const videoOptions = findOptionsForSelectFilter('videoamenities', config.custom.filters);
  const photoOptions = findOptionsForSelectFilter('photoamenities', config.custom.filters);
  const weddingOptions = findOptionsForSelectFilter('weddingamenities', config.custom.filters);
  const performanceOptions = findOptionsForSelectFilter(
    'performanceamenities',
    config.custom.filters
  );
  const cocktailOptions = findOptionsForSelectFilter('cocktailamenities', config.custom.filters);
  const workshopOptions = findOptionsForSelectFilter('workshopamenities', config.custom.filters);
  const reatilOptions = findOptionsForSelectFilter('retailamenities', config.custom.filters);
  const audioOptions = findOptionsForSelectFilter('audioamenities', config.custom.filters);
  const allAmenitiesList = [
    ...generalOptions,
    ...meetingOptions,
    ...coporateOptions,
    ...partiesOptions,
    ...cookingOptions,
    ...videoOptions,
    ...photoOptions,
    ...weddingOptions,
    ...performanceOptions,
    ...cocktailOptions,
    ...workshopOptions,
    ...reatilOptions,
    ...audioOptions,
  ];

  const filterAmenities = allAmenitiesList
    .map((val, i) => {
      return val.amenitiesList ? val.amenitiesList : val;
    })
    .flatMap(values => {
      return values;
    });

  const selectedConfigOptions = filterAmenities.filter(o => selectedOptions.find(s => s === o.key));

  function uniqByKeepLast(data, key) {
    return [...new Map(data.map(x => [key(x), x])).values()];
  }

  const selectedOrginalArray = uniqByKeepLast(selectedConfigOptions, it => it.label);

  return (
    <div className={css.sectionFeatures}>
      {selectedOrginalArray && selectedOrginalArray.length > 0 ? (
        <h3 className={css.featuresAmenitiesTitle}>
          <FormattedMessage id="ListingPage.featuresTitle" />
        </h3>
      ) : (
        ''
      )}
      <PropertyGroup
        id="ListingPage.amenities"
        options={selectedOrginalArray}
        selectedOptions={selectedOptions}
        twoColumns={selectedOrginalArray.length > 5}
      />
    </div>
  );
};

export default SectionAmenitiesMaybe;
