import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Link } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, PrevButton, NextButton } from './EmblaCarouselArrowDotBtn';

import css from './ListingPage.module.css';
import virtualimage from '../../assets/360-degree.png';
import { retrievePaymentIntent } from '../../ducks/stripe.duck';
import virtualMainImage from '../../assets/walkthrough-default-image.jpg';

const SectionMediaLinkAvlMaybe = props => {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [emblaRef, emblaApi] = useEmblaCarousel({});

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback(index => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const { walkthroughYT } = publicData;

  const replaceWordWatch = walkthroughYT
    ? walkthroughYT.map((val, index) => {
        let replaceLetter =
          val && val.ytURl && val.ytURl.indexOf > -1
            ? val.ytURl.replace('watch?v=', 'embed/')
            : val.ytURl;
        return replaceLetter ? replaceLetter : null;
      })
    : null;

  const carouselMain = walkthroughYT
    ? replaceWordWatch.map((val, index) => {
        return (
          <div className={css.embla__slide} key={index}>
            <div className={css.iframeWrap}>
              {/* <video>
                <source></source>
              </video> */}
              {/* <iframe
                width="100%"
                height="100%"
                src={val}
                frameBorder="0"
                allowFullScreen
                title="Embedded youtube"
              /> */}
              <img className={css.virtualMainImage} src={virtualMainImage} alt="View Walkthrough"/>

              <a href={val} className={css.virtualButton} target="_blank" rel="noopener">
                <img src={virtualimage} alt="View Walkthrough Icon"/>
                <span>View Walkthrough</span>
              </a>
            </div>
          </div>
        );
        // return val.ytURl.replace("watch?v=","embed/");
      })
    : null;

  const walkThroughFlag = Object.keys(walkthroughYT[0]).length > 0 ? true : false;
  return (
    <>
      {walkthroughYT[0].ytURl ? (
        <>
          <div className={css.sectionFeatures}>
            {walkThroughFlag ? (
              <>
                <h2 className={`${css.vitualWalkTitle} ${css.featuresTitle} `}>
                  <FormattedMessage id="ListingPage.virtualWalkthroughLinkTitle" />
                </h2>

                {walkthroughYT.length > 1 ? (
                  <>
                    <div className={css.walkthroughCarousel}>
                      <div className={css.floorplan__embla}>
                        <div className={css.floorplan__embla__viewport} ref={emblaRef}>
                          <div className={css.floorplan__embla__container}>{carouselMain}</div>
                        </div>

                        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                      </div>
                      <div className={css.floorplan__embla__dots}>
                        {scrollSnaps.map((_, index) => (
                          <DotButton
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={css.iframeDiv}>
                    {/* <iframe
                      width="100%"
                      height="100%"
                      src={replaceWordWatch[0]}
                      frameBorder="0"
                      allowFullScreen
                      title="Embedded youtube"
                    /> */}
                    <img className={css.virtualMainImage} src={virtualMainImage} alt="View Walkthrough"/>
                    <a href={replaceWordWatch[0]} className={css.virtualButton} target="_blank" rel="noopener">
                      <img src={virtualimage}  alt="View Walkthrough Icon"/>
                      <span>View Walkthrough</span>
                    </a>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default SectionMediaLinkAvlMaybe;
