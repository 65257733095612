import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  const category = publicData.category;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const subCategoryList = categoryOptions.map(val => {
    if (Object.keys(val).includes('subcategory')) {
      return val.subcategory;
    }
  });
  const combineOptions = [...categoryOptions, ...subCategoryList].flat().filter(element => {
    return element !== undefined;
  });

  const categoryLabel = combineOptions
    .filter((val, index) => {
      return val.key === category && val.label;
    })
    .map(values => {
      return values.label;
    });

  return (
    <div className={`${css.sectionFeatures} ${css.sectionFeaturesAlign}`}>
      <h3 className={`${css.featuresTitle} ${css.spaceHighlights} ${css.categoryLabels}`}>
        <FormattedMessage id="ListingPage.categoryTypeTitle" />
        <span className={css.categoryName}>{categoryLabel[0]}</span>
      </h3>
      <h3 className={`${css.featuresTitle} ${css.spaceHighlights}`}>
        <FormattedMessage id="ListingPage.sqftTitle" />
        <span className={css.sqFtNum}>{publicData.sqFt} sq. feet</span>
      </h3>
      <h3 className={`${css.featuresTitle} ${css.spaceHighlights}`}>
        <FormattedMessage id="ListingPage.capacity" />
        <span className={css.sqFtNum}>
          {publicData.minimumcapacity} - {publicData.maximumcapacity} people
        </span>
      </h3>
    </div>
  );
};

export default SectionFeaturesMaybe;
