import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const SectionTagsMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const tags = publicData.additionalTags ? publicData.additionalTags : null;
  // const otherRUles = publicData.otherrules ? publicData.otherrules : '';
  // const tagsOptions = findOptionsForSelectFilter('tags', config.custom.filters);

  // console.log(tags,"tags")

  // const filterTagsData = tags
  //   ? tagsOptions
  //       .filter((val, index) => {
  //         return tags.indexOf(val.key) != -1 ? val.label : '';
  //       })
  //       .map(values => {
  //         return values.label;
  //       })
  //   : null;

  const mergeTags = tags ? tags.join(', ') : null;

  return (
    <>
      {mergeTags ? (
        <div className={css.sectionFeatures}>
          <div className={css.sectionTags}>
            <h2 className={css.policiesHeading}>
              <FormattedMessage id="ListingPage.spaceTags" />
            </h2>
            <span>{mergeTags}</span>
            {/* {filterTagsData.map(tag => {
          return <span>{tag},&nbsp;</span>;
        })} */}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SectionTagsMaybe;
