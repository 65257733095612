import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const SectionPoliciesMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const cancellationPolicy = publicData.cancellationPolicies;
  const categoryOptions = findOptionsForSelectFilter(
    'cancellationPolicyOptions',
    config.custom.filters
  );

  const combineOptions = [...categoryOptions].flat().filter(element => {
    return element !== undefined;
  });

  const cancellationPolicyLabel = combineOptions
    .filter((val, index) => {
      return val.key === cancellationPolicy && val.label;
    })
    .map(values => {
      return values.label;
    });

  return (
    <div className={css.policiesWrap}>
      {publicData.minimumDeposit && (
        <div className={css.depositPolicies}>
          <h2 className={css.policiesHeading}>
            <FormattedMessage id="ListingPage.spaceDepositPolicies" />
          </h2>
          <p className={css.policiesData}>{publicData.minimumDeposit}</p>
        </div>
      )}

      {publicData.cancellationPolicies && (
        <div className={css.cancellationPolicies}>
          <h2 className={css.policiesHeading}>
            <FormattedMessage id="ListingPage.cancellationPolicies" />
          </h2>
          <p className={css.policiesData}>{cancellationPolicyLabel}</p>
        </div>
      )}

      {publicData.minMaxBooking && (
        <div className={css.minMaxBookingPolicies}>
          <h2 className={css.policiesHeading}>
            <FormattedMessage id="ListingPage.minMaxBookingPolicies" />
          </h2>
          <p className={css.policiesData}>{publicData.minMaxBooking}</p>
        </div>
      )}

      {publicData.checkInCheckOutRules && (
        <div className={css.checkinCheckoutRules}>
          <h2 className={css.policiesHeading}>
            <FormattedMessage id="ListingPage.checkinCheckoutRules" />
          </h2>
          <p className={css.policiesData}>{publicData.checkInCheckOutRules}</p>
        </div>
      )}
    </div>
  );
};

export default SectionPoliciesMaybe;
