import React from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ListingPage.module.css';

const { Money } = sdkTypes;

const dailyPriceData = (dailyPriceAsMoney, intl) => {
  if (dailyPriceAsMoney && dailyPriceAsMoney.currency == config.currency) {
    const formattedDailyPrice = formatMoney(intl, dailyPriceAsMoney);
    return { formattedDailyPrice, dailyPrice: formattedDailyPrice };
  } else if (dailyPriceAsMoney) {
    return {
      formattedDailyPrice: `(${dailyPriceAsMoney.currency})`,
      dailyPrice: `Unsupported currency (${dailyPriceAsMoney.currency})`,
    };
  }
  return {};
};

const SectionHourlyAndDailyPriceMaybe = props => {
  const { publicData, intl } = props;
  if (!publicData) {
    return null;
  }

  const hourlyPrice = props.priceTitle;

  const dailyPriceReceived = publicData && publicData.dailyPrice ? publicData.dailyPrice : null;

  const dailyPriceAsMoney = dailyPriceReceived
    ? new Money(dailyPriceReceived.amount, dailyPriceReceived.currency)
    : null;

  const { formattedDailyPrice, dailyPrice } = dailyPriceData(dailyPriceAsMoney, intl);

  return (
    <div className={`${css.sectionFeatures}, ${css.sectionOperationalHrsAlign}`}>
      <h3 className={`${css.operationlHrs}, ${css.spaceHighlights}`}>
        <FormattedMessage id="ListingPage.pricingHourlyDetails" />
        <span className={` ${css.categoryName}`}>
          &nbsp;
          {hourlyPrice} <span className={css.priceDesc}>(Starting from)</span>
        </span>
      </h3>
      <h3 className={`${css.operationlHrs}, ${css.spaceHighlights}`}>
        <FormattedMessage id="ListingPage.pricingDailyDetails" />
        <span className={` ${css.categoryName}`}>
          &nbsp;
          {dailyPrice} <span className={css.priceDesc}>(starting from)</span>
        </span>
      </h3>
    </div>
  );
};

SectionHourlyAndDailyPriceMaybe.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

SectionHourlyAndDailyPriceMaybe.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SectionHourlyAndDailyPriceMaybe);
